import Axios from "axios";
import IViaCEPResponse from "./interfaces/via-cep-response";

function cepURL(cep: string): string {
  return `/${cep.replaceAll(/[^\d]/g, "")}/json`;
}

const axios = Axios.create({
  baseURL: "https://viacep.com.br/ws/",
});

// executado antes da requisição ser enviada
axios.interceptors.request.use(
  function (config) {
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// executado após a resposta ser recebida
axios.interceptors.response.use(
  function (response) {
    // disparado quando o status da requisição está na faixa 2xx
    return response;
  },
  function (error) {
    // disparado quando o status da requisição está fora da faixa 2xx ou a requisição não obteve resposta
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
    } else {
      // Something happened in setting up the request that triggered an Error
    }

    return Promise.reject(error);
  }
);

export async function queryCEP(cep: string): Promise<IViaCEPResponse> {
  const response = await axios.get(cepURL(cep));
  const data = response.data;
  return data;
}
