
import Api from "@/lib/api";
import Vue, { PropType } from "vue";
import { Rq, Table } from "@/lib/amdt";
import VForm from "@/lib/types/v-form";
import WorgError from "@/lib/worg-error";
import {
  required,
  minLength,
  equalsLength,
  maxLength,
} from "@/lib/validations";
import IPersonAddress from "@/lib/interfaces/person-address";
import vAlertMessage from "@/components/layout/v-alert-message.vue";
import ADialog from "@/components/elements/a-dialog.vue";
import ABtn from "@/components/elements/a-btn.vue";
import ATextField from "@/components/elements/a-text-field.vue";
import ASwitch from "@/components/elements/a-switch.vue";
import ABtnDialogConfirm from "@/components/elements/a-btn-dialog-confirm.vue";
import ATextFieldCep from "@/components/elements/a-text-field-cep.vue";
import IViaCEPResponse from "@/lib/interfaces/via-cep-response";
import ASelect from "@/components/elements/a-select.vue";
import IPeopleAddressType from "@/lib/interfaces/people-address-type";

export default Vue.extend({
  name: "PersonAddress",

  components: {
    vAlertMessage,
    ADialog,
    ABtn,
    ATextField,
    ASwitch,
    ABtnDialogConfirm,
    ATextFieldCep,
    ASelect,
  },

  props: {
    personId: {
      type: String as PropType<string>,
      required: true,
    },

    personAddress: {
      type: Object as PropType<IPersonAddress | undefined>,
    },

    value: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      valid: false,
      ret_id: 1,
      ret_msg: "",

      addressTypes: new Array<IPeopleAddressType>(),

      dataPersonAddress: {
        id: "",
        pessoa_id: "",
        tipo_id: "",
        cep: "",
        endereco: "",
        numero: "",
        bairro: "",
        complemento: "",
        cidade: "",
        uf: "",
        referencia: "",
        desativado: "0",
      } as IPersonAddress,
    };
  },

  watch: {
    value() {
      if (!this.value) this.$emit("update:person-address", undefined);

      this.dataPersonAddress = this.personAddress
        ? { ...this.personAddress }
        : {
            id: "",
            pessoa_id: this.personId,
            tipo_id: "",
            cep: "",
            endereco: "",
            numero: "",
            bairro: "",
            complemento: "",
            cidade: "",
            uf: "",
            referencia: "",
            desativado: "0",
          };

      this.form?.resetValidation();
    },
  },

  computed: {
    form(): VForm {
      return this.$refs.form as VForm;
    },

    cTextSave(): string {
      return this.dataPersonAddress.id ? "Alterar" : "Salvar";
    },

    isMobile(): boolean {
      return this.$vuetify.breakpoint.mobile;
    },

    cWidth(): string {
      return this.isMobile ? "100%" : "35%";
    },
  },

  created() {
    this.getPeopleAddressTypes().then();
  },

  methods: {
    minLength,
    equalsLength,
    maxLength,
    required,

    async evOnSubmit() {
      try {
        this.loading = true;
        this.ret_id = 1;
        this.ret_msg = "";

        const rq = new Rq("pessoas_enderecos.write");

        const tblPessoasEnderecos = new Table("pessoas_enderecos");

        const obj = {
          ...this.dataPersonAddress,
          id: this.dataPersonAddress.id || "default",
          pessoa_id: this.personId,
        };
        tblPessoasEnderecos.setColsFromObject(obj);
        tblPessoasEnderecos.addRowsFromObject(obj);

        rq.addTable(tblPessoasEnderecos);

        const rsp = await Api.request(rq);

        if (rsp.ret_id < 1) {
          this.ret_id = rsp.ret_id;
          this.ret_msg = rsp.ret_msg;

          const errorName = WorgError.getErrorByCode(this.ret_id).name;
          if (errorName === "VldError") this.form.validate();
          else if (errorName === "ErrDupEntry")
            this.ret_msg = "Já existe uma pessoa-endereço com esse nome";

          return;
        }

        this.ret_msg = `Endereço ${
          this.dataPersonAddress?.id ? "atualizado" : "cadastrado"
        } com sucesso`;

        this.dataPersonAddress.id = rsp.ret_id.toString();

        this.$emit("updated");
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },

    async getPeopleAddressTypes() {
      try {
        this.loading = true;
        this.ret_id = 1;
        this.ret_msg = "";

        const rq = new Rq("pessoas_enderecos_tipos.read");
        const rsp = await Api.request(rq);

        if (rsp.ret_id < 1) {
          this.ret_id = rsp.ret_id;
          this.ret_msg = rsp.ret_msg;
          return;
        }

        this.addressTypes =
          rsp.getTable("pessoas_enderecos_tipos")?.getRowsObject() ?? [];
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },

    clearForm() {
      this.dataPersonAddress = {
        id: "",
        pessoa_id: this.personId,
        tipo_id: "",
        cep: "",
        endereco: "",
        numero: "",
        bairro: "",
        complemento: "",
        cidade: "",
        uf: "",
        referencia: "",
        desativado: "0",
      };

      this.form?.resetValidation();
    },

    evOnCEPFound(data: IViaCEPResponse) {
      this.dataPersonAddress.endereco = data.logradouro;
      this.dataPersonAddress.cidade = data.localidade;
      this.dataPersonAddress.bairro = data.bairro;
      this.dataPersonAddress.complemento = data.complemento;
      this.dataPersonAddress.uf = data.uf;
    },
  },
});
