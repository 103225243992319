
import Vue from "vue";
import IPerson from "@/lib/interfaces/person";
import PeopleList from "@/components/subviews/people/people-list.vue";
import PeopleMaintenance from "@/components/subviews/people/people-maintenance.vue";

export default Vue.extend({
  name: "People",

  components: { PeopleList, PeopleMaintenance },

  data() {
    return {
      ret_id: 1,
      ret_msg: "",

      refresh: 0,

      currentTab: 0,

      person: {} as IPerson,
    };
  },
});
