
import { cpf } from "@/lib/validations";
import Vue, { PropType } from "vue";
import aTextField from "./a-text-field.vue";

export default Vue.extend({
  name: "ATextFieldCPF",

  components: { aTextField },

  props: {
    backgroundColor: {
      type: String as PropType<string>,
      default: "inputbg",
    },

    disabled: {
      type: Boolean as PropType<boolean>,
      default: false,
    },

    value: {
      type: String as PropType<string>,
      default: "",
    },

    raw: {
      type: String as PropType<string>,
      default: "",
    },

    label: {
      type: String as PropType<string>,
      required: true,
    },

    rules: {
      type: Array as PropType<Array<(value: string) => string | boolean>>,
      default: () => [],
    },
  },

  computed: {
    cRules(): Array<(value: string) => string | boolean> {
      return [...this.rules, cpf];
    },
  },

  watch: {
    value() {
      this.emit(this.value);
    },
  },

  methods: {
    filter(event: KeyboardEvent) {
      if (/^[0-9]+$/.test(event.key)) return true;
      else event.preventDefault();
    },

    emit(value: string) {
      const raw = value.replace(/[^\d]/g, "");
      this.$emit("update:raw", raw);

      let rule: RegExp;
      let format: string;
      if (raw.length >= 3 && raw.length < 6) {
        rule = /^(\d{3})(\d*)$/;
        format = "$1.$2";
      } else if (raw.length >= 6 && raw.length < 9) {
        rule = /^(\d{3})(\d{3})(\d*)$/;
        format = "$1.$2.$3";
      } else if (raw.length >= 9 && raw.length <= 11) {
        rule = /^(\d{3})(\d{3})(\d{3})(\d*)$/;
        format = "$1.$2.$3-$4";
      } else {
        this.$emit("input", raw);
        return;
      }

      value = raw.replace(rule, format);
      this.$emit("input", value);
    },
  },
});
