
import Api from "@/lib/api";
import Vue, { PropType } from "vue";
import { Rq, Table } from "@/lib/amdt";
import VForm from "@/lib/types/v-form";
import WorgError from "@/lib/worg-error";
import { required, maxLength, email } from "@/lib/validations";
import IPersonContact from "@/lib/interfaces/person-contact";
import vAlertMessage from "@/components/layout/v-alert-message.vue";
import IPeopleContactType from "@/lib/interfaces/people-contact-type";
import ADialog from "@/components/elements/a-dialog.vue";
import ABtn from "@/components/elements/a-btn.vue";
import ATextField from "@/components/elements/a-text-field.vue";
import ASwitch from "@/components/elements/a-switch.vue";
import AAutocomplete from "@/components/elements/a-autocomplete.vue";
import ABtnDialogConfirm from "@/components/elements/a-btn-dialog-confirm.vue";
import ATextFieldPhone from "@/components/elements/a-text-field-phone.vue";

export default Vue.extend({
  name: "PersonContact",

  components: {
    vAlertMessage,
    ADialog,
    ABtn,
    ATextField,
    ASwitch,
    AAutocomplete,
    ABtnDialogConfirm,
    ATextFieldPhone,
  },

  props: {
    personId: {
      type: String as PropType<string>,
      required: true,
    },

    personContact: {
      type: Object as PropType<IPersonContact | undefined>,
    },

    value: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      valid: false,
      ret_id: 1,
      ret_msg: "",

      peopleContactTypes: new Array<IPeopleContactType>(),

      dataPersonContact: {
        id: "",
        pessoa_id: "",
        pessoa_contato_tipo_id: "",
        descricao: "",
        contato: "",
        desativado: "0",
      } as IPersonContact,
    };
  },

  watch: {
    value() {
      if (!this.value) this.$emit("update:person-contact", undefined);

      this.dataPersonContact = this.personContact
        ? { ...this.personContact }
        : {
            id: "",
            descricao: "",
            pessoa_contato_tipo_id: "",
            contato: "",
            pessoa_id: this.personId,
            desativado: "0",
          };

      this.form?.resetValidation();
    },
  },

  computed: {
    form(): VForm {
      return this.$refs.form as VForm;
    },

    cTextSave(): string {
      return this.dataPersonContact.id ? "Alterar" : "Salvar";
    },

    isMobile(): boolean {
      return this.$vuetify.breakpoint.mobile;
    },

    cWidth(): string {
      return this.isMobile ? "100%" : "35%";
    },

    contactType(): "telefone" | "email" | "" | undefined {
      return this.peopleContactTypes.find(
        (item) => item.id === this.dataPersonContact.pessoa_contato_tipo_id
      )?.identificador;
    },
  },

  mounted() {
    this.getPeopleContactTypes().then();
  },

  methods: {
    maxLength,
    required,
    email,

    async evOnSubmit() {
      try {
        this.loading = true;
        this.ret_id = 1;
        this.ret_msg = "";

        const rq = new Rq("pessoas_contatos.write");

        const tblPessoasContatos = new Table("pessoas_contatos");

        const obj = {
          ...this.dataPersonContact,
          id: this.dataPersonContact.id || "default",
          pessoa_id: this.personId,
        };
        tblPessoasContatos.setColsFromObject(obj);
        tblPessoasContatos.addRowsFromObject(obj);

        rq.addTable(tblPessoasContatos);

        const rsp = await Api.request(rq);

        if (rsp.ret_id < 1) {
          this.ret_id = rsp.ret_id;
          this.ret_msg = rsp.ret_msg;

          const errorName = WorgError.getErrorByCode(this.ret_id).name;
          if (errorName === "VldError") this.form.validate();
          else if (errorName === "ErrDupEntry")
            this.ret_msg = "Já existe uma pessoa-contato com esse nome";

          return;
        }

        this.ret_msg = `Contato da pessoa ${
          this.dataPersonContact.id ? "atualizado" : "cadastrado"
        } com sucesso`;

        this.dataPersonContact.id = rsp.ret_id.toString();

        this.$emit("updated");
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },

    clearForm() {
      this.dataPersonContact = {
        id: "",
        pessoa_id: this.personId,
        pessoa_contato_tipo_id: "",
        descricao: "",
        contato: "",
        desativado: "0",
      };

      this.form?.resetValidation();
    },

    async getPeopleContactTypes() {
      try {
        this.loading = true;
        this.ret_id = 1;
        this.ret_msg = "";

        const rq = new Rq("pessoas_contatos_tipos.read");
        const rsp = await Api.request(rq);

        if (rsp.ret_id < 1) {
          this.ret_id = rsp.ret_id;
          this.ret_msg = rsp.ret_msg;
          return;
        }

        this.peopleContactTypes =
          rsp.getTable("pessoas_contatos_tipos")?.getRowsObject() ?? [];
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
  },
});
