
import Api from "@/lib/api";
import { Rq } from "@/lib/amdt";
import Vue, { PropType } from "vue";
import { DataOptions } from "vuetify";
import IPerson from "@/lib/interfaces/person";
import vAlertMessage from "@/components/layout/v-alert-message.vue";
import ABtn from "@/components/elements/a-btn.vue";
import ADate from "@/lib/a-date";
import ATextField from "@/components/elements/a-text-field.vue";
import { cpfCnpj } from "@/lib/a-format";
import ADataTable from "@/components/elements/a-data-table.vue";
import ACard from "@/components/elements/a-card.vue";
import ASelectStatus from "@/components/elements/a-select-status.vue";

export default Vue.extend({
  name: "PeopleList",

  components: {
    vAlertMessage,
    ABtn,
    ATextField,
    ADataTable,
    ACard,
    ASelectStatus,
  },

  props: {
    person: {
      type: Object as PropType<IPerson>,
      required: true,
    },

    forceRefresh: {
      type: Number as PropType<number>,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      ret_id: 1,
      ret_msg: "",

      total: 0,
      search: "",
      desativado: "0",

      options: {} as DataOptions,
      people: new Array<IPerson>(),

      headers: [
        // { text: "Referente", value: "referente_id", sortable: false },
        { text: "Nome", value: "nome_raz", sortable: false },
        { text: "Apelido", value: "apelido_fant", sortable: false },
        { text: "CPF/CNPJ", value: "cCpfCnpj", sortable: false },
        { text: "Inscrição Estadual", value: "doc_ie", sortable: false },
        { text: "Tipo", value: "tipo", sortable: false },
        {
          text: "Informação Adicional",
          value: "inf_adicional",
          sortable: false,
        },
        { text: "Data Criação", value: "cDataCriacao", sortable: false },
        { text: "Status", value: "cDesativado", sortable: false },
        { text: "Ações", value: "actions", sortable: false },
      ],
    };
  },

  watch: {
    forceRefresh() {
      this.getPeople().then();
    },

    options: {
      deep: true,
      handler() {
        this.getPeople().then();
      },
    },
  },

  computed: {
    cPeople(): Array<Record<string, unknown>> {
      return this.people.map((person) => ({
        ...person,

        cDataCriacao: ADate.parse(person.data_criacao).localeDateTimeString,
        cCpfCnpj: cpfCnpj(person.cpf_cnpj),

        tipo: person.tipo == "1" ? "FÍSICA" : "JURÍDICA",

        cDesativado: person.desativado === "0" ? "Ativo" : "Inativo",
      }));
    },
  },

  methods: {
    async openPerson(id: string) {
      const person = this.people.find((person) => person.id === id);
      this.$emit("update:person", { ...person });
    },

    async openDetails(id: string) {
      this.$router.push({ name: "person", params: { id } });
    },

    async getPeople() {
      try {
        this.loading = true;
        this.ret_id = 1;
        this.ret_msg = "";

        const rq = new Rq("pessoas.read", {
          search: this.search,
          desativado: this.desativado,
          itemsPerPage: this.options.itemsPerPage.toString(),
          page: this.options.page.toString(),
          sortBy: this.options.sortBy[0] ?? "id",
          sortOrder: this.options.sortDesc[0] ? "DESC" : "ASC",
        });
        const rsp = await Api.request(rq);

        if (rsp.ret_id < 1) {
          this.ret_id = rsp.ret_id;
          this.ret_msg = rsp.ret_msg;
          return;
        }

        this.total = Number(rsp.params["total"]);
        this.options.itemsPerPage = Number(rsp.params["itemsPerPage"]);
        this.options.page = Number(rsp.params["page"]);

        this.people = rsp.getTable("pessoas")?.getRowsObject() ?? [];
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
  },
});
