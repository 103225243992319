
import ABtn from "@/components/elements/a-btn.vue";
import ATextField from "@/components/elements/a-text-field.vue";
import vAlertMessage from "@/components/layout/v-alert-message.vue";
import { Rq } from "@/lib/amdt";
import Api from "@/lib/api";
import IPeopleContactType from "@/lib/interfaces/people-contact-type";
import IPersonContact from "@/lib/interfaces/person-contact";
import Vue, { PropType } from "vue";
import { DataOptions } from "vuetify";
import ADataTable from "@/components/elements/a-data-table.vue";
import ASelectStatus from "@/components/elements/a-select-status.vue";
import ACard from "@/components/elements/a-card.vue";
import PersonContact from "./person-contact.vue";

export default Vue.extend({
  name: "PersonContactsList",

  components: {
    vAlertMessage,
    ABtn,
    ATextField,
    ADataTable,
    ASelectStatus,
    ACard,
    PersonContact,
  },

  props: {
    personId: {
      type: String as PropType<string>,
      required: true,
    },

    forceRefresh: {
      type: Number as PropType<number>,
      required: true,
    },
  },

  data() {
    return {
      pendingConnections: 0,
      ret_id: 1,
      ret_msg: "",
      desativado: "0",

      personContactDialog: false,

      total: 0,
      search: "",

      options: {} as DataOptions,

      personContacts: new Array<IPersonContact>(),
      peopleContactTypes: new Array<IPeopleContactType>(),
      personContact: undefined as IPersonContact | undefined,

      headers: [
        { text: "Tipo", value: "cPersonContactType", sortable: false },
        { text: "Contato", value: "contato", sortable: false },
        { text: "Descrição", value: "descricao", sortable: false },
        { text: "Status", value: "cDesativado", sortable: false },
        { text: "Ações", value: "actions", sortable: false },
      ],
    };
  },

  watch: {
    personId() {
      this.getPersonContacts().then();
    },

    forceRefresh() {
      this.getPersonContacts().then();
    },

    options: {
      deep: true,
      handler() {
        this.getPersonContacts().then();
      },
    },
  },

  computed: {
    cPersonContacts(): Array<Record<string, unknown>> {
      return this.personContacts.map((personContact) => ({
        ...personContact,

        cPersonContactType:
          this.peopleContactTypes.find(
            (typePeopleContact) =>
              typePeopleContact.id === personContact.pessoa_contato_tipo_id
          )?.descricao ?? personContact.pessoa_contato_tipo_id,

        cDesativado: personContact.desativado === "0" ? "Ativo" : "Inativo",
      }));
    },

    loading: {
      get(): boolean {
        return this.pendingConnections > 0;
      },
      set(val: boolean) {
        this.pendingConnections += val ? 1 : -1;
      },
    },
  },

  mounted() {
    this.getPeopleContactTypes().then();
  },

  methods: {
    openPersonContact(id: string) {
      const personContact = this.personContacts.find(
        (personContact) => personContact.id === id
      );

      this.personContact = { ...personContact } as IPersonContact;

      this.personContactDialog = true;
    },

    async getPersonContacts() {
      try {
        if (!this.personId) {
          this.personContacts = [];
          return;
        }

        this.loading = true;
        this.ret_id = 1;
        this.ret_msg = "";

        const rq = new Rq("pessoas_contatos.read", {
          pessoaId: this.personId,
          search: this.search,
          desativado: this.desativado,
          itemsPerPage: this.options.itemsPerPage.toString(),
          page: this.options.page.toString(),
          sortBy: this.options.sortBy[0] ?? "id",
          sortOrder: this.options.sortDesc[0] ? "DESC" : "ASC",
        });
        const rsp = await Api.request(rq);

        if (rsp.ret_id < 1) {
          this.ret_id = rsp.ret_id;
          this.ret_msg = rsp.ret_msg;
          return;
        }

        this.total = Number(rsp.params["total"]);
        this.options.itemsPerPage = Number(rsp.params["itemsPerPage"]);
        this.options.page = Number(rsp.params["page"]);

        this.personContacts =
          rsp.getTable("pessoas_contatos")?.getRowsObject() ?? [];
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },

    async getPeopleContactTypes() {
      try {
        this.loading = true;
        this.ret_id = 1;
        this.ret_msg = "";

        const rq = new Rq("pessoas_contatos_tipos.read");
        const rsp = await Api.request(rq);

        if (rsp.ret_id < 1) {
          this.ret_id = rsp.ret_id;
          this.ret_msg = rsp.ret_msg;
          return;
        }

        this.peopleContactTypes =
          rsp.getTable("pessoas_contatos_tipos")?.getRowsObject() ?? [];
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
  },
});
