
import Api from "@/lib/api";
import Vue, { PropType } from "vue";
import { Rq, Table } from "@/lib/amdt";
import VForm from "@/lib/types/v-form";
import WorgError from "@/lib/worg-error";
import {
  arrayRequired,
  required,
  maxLength,
  cpf,
  cnpj,
} from "@/lib/validations";
import IPerson from "@/lib/interfaces/person";
import vAlertMessage from "@/components/layout/v-alert-message.vue";
import ABtnDialogConfirm from "@/components/elements/a-btn-dialog-confirm.vue";
import ATextField from "@/components/elements/a-text-field.vue";
import ABtn from "@/components/elements/a-btn.vue";
import ASwitch from "@/components/elements/a-switch.vue";
import ASelect from "@/components/elements/a-select.vue";
import ACard from "@/components/elements/a-card.vue";
import ATextFieldCpf from "@/components/elements/a-text-field-cpf.vue";
import ATextFieldCnpj from "@/components/elements/a-text-field-cnpj.vue";
import PersonContactsList from "./maintenance/person-contacts-list.vue";
import PersonAddressesList from "./maintenance/person-addresses-list.vue";
import PersonContact from "./maintenance/person-contact.vue";
import PersonAddress from "./maintenance/person-address.vue";
import AAutocomplete from "@/components/elements/a-autocomplete.vue";
import ITransactioner from "@/lib/interfaces/transactioner";
import IPersonTransactioner from "@/lib/interfaces/person-transactioner";

export default Vue.extend({
  name: "PeopleMaintenance",

  components: {
    vAlertMessage,
    ABtnDialogConfirm,
    ATextField,
    ABtn,
    ASwitch,
    ASelect,
    ACard,
    ATextFieldCpf,
    ATextFieldCnpj,
    PersonContactsList,
    PersonAddressesList,
    PersonContact,
    PersonAddress,
    AAutocomplete,
  },

  props: {
    person: {
      type: Object as PropType<IPerson>,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      valid: false,
      ret_id: 1,
      ret_msg: "",

      personContactDialog: false,
      personAddressDialog: false,

      forceRefreshContacts: 0,
      forceRefreshAddresses: 0,

      transactioners: new Array<ITransactioner>(),
      personTransactioners: new Array<IPersonTransactioner>(),
      updatedTransactioners: new Array<string>(),

      dataPerson: {
        id: "",
        referente_id: "default",
        empresa_id: "default",
        nome_raz: "",
        apelido_fant: "",
        cpf_cnpj: "",
        doc_ie: "",
        tipo: "",
        inf_adicional: "",
        data_criacao: "default",
        desativado: "0",
      } as IPerson,

      PESSOA_FISICA: "1",
      PESSOA_JURIDICA: "2",
      pessoaTipos: [
        { value: "", text: "Escolha o tipo da pessoa" },
        { value: "1", text: "Pessoa Física" },
        { value: "2", text: "Pessoa Jurídica" },
      ],
    };
  },

  watch: {
    person: {
      deep: true,
      handler() {
        this.ret_id = 1;
        this.ret_msg = "";
        this.dataPerson = {
          ...this.person,
        };
        this.getPersonTransactioners().then();
      },
    },

    personTransactioners() {
      this.updatedTransactioners = this.personTransactioners.map(
        (item) => item.transacionador_id
      );
    },
  },

  computed: {
    form(): VForm {
      return this.$refs.form as VForm;
    },

    cTextSave(): string {
      return this.dataPerson.id ? "Alterar" : "Salvar";
    },

    cNomeLabel(): string {
      const tipo = this.dataPerson.tipo as "1" | "2";

      return (
        {
          "1": "Nome",
          "2": "Razão social",
        }[tipo] || "Nome ou razão social"
      );
    },

    cNomeFantasiaLabel(): string {
      const tipo = this.dataPerson.tipo as "1" | "2";

      return (
        {
          "1": "Apelido",
          "2": "Nome fantasia",
        }[tipo] || "Apelido ou nome fantasia"
      );
    },

    personId(): string {
      return this.dataPerson.id;
    },
  },

  mounted() {
    this.getTransactioners().then();
  },

  methods: {
    cpf,
    cnpj,
    maxLength,
    required,
    arrayRequired,

    async evOnSubmit() {
      try {
        this.loading = true;
        this.ret_id = 1;
        this.ret_msg = "";

        const rq = new Rq("pessoas.write");

        const tblPeople = new Table("pessoas");

        const obj = {
          ...this.dataPerson,
          id: this.dataPerson.id || "default",
          doc_ie:
            this.dataPerson.tipo === this.PESSOA_JURIDICA
              ? this.dataPerson.doc_ie
              : "",
        };
        tblPeople.setColsFromObject(obj);
        tblPeople.addRowsFromObject(obj);

        const tblTransactioners = new Table("transacionadores");
        const data = { transacionadores: this.updatedTransactioners.join(",") };
        tblTransactioners.setColsFromObject(data);
        tblTransactioners.addRowsFromObject(data);
        rq.addTables(tblPeople, tblTransactioners);

        const rsp = await Api.request(rq);

        if (rsp.ret_id < 1) {
          this.ret_id = rsp.ret_id;
          this.ret_msg = rsp.ret_msg;

          const errorName = WorgError.getErrorByCode(this.ret_id).name;
          if (errorName === "VldError") this.form.validate();
          else if (errorName === "ErrDupEntry")
            this.ret_msg = "Já existe uma pessoa com esse nome";

          return;
        }

        this.ret_msg = `Pessoa ${
          this.dataPerson.id ? "atualizada" : "cadastrada"
        } com sucesso`;

        this.dataPerson.id = rsp.ret_id.toString();

        this.$emit("updated");
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },

    clearForm() {
      this.dataPerson = {
        id: "",
        referente_id: "default",
        empresa_id: "default",
        nome_raz: "",
        apelido_fant: "",
        cpf_cnpj: "",
        doc_ie: "",
        tipo: "",
        inf_adicional: "",
        data_criacao: "default",
        desativado: "0",
      };
      this.updatedTransactioners = [];

      this.form.resetValidation();
    },

    async getTransactioners() {
      try {
        this.loading = true;
        this.ret_id = 1;
        this.ret_msg = "";

        const rq = new Rq("transacionadores.read");
        const rsp = await Api.request(rq);

        if (rsp.ret_id < 1) {
          this.ret_id = rsp.ret_id;
          this.ret_msg = rsp.ret_msg;
          return;
        }

        this.transactioners =
          rsp.getTable("transacionadores")?.getRowsObject() ?? [];
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },

    async getPersonTransactioners() {
      try {
        if (!this.dataPerson.id) {
          this.personTransactioners = [];
          return;
        }

        this.loading = true;
        this.ret_id = 1;
        this.ret_msg = "";

        const rq = new Rq("pessoas_transacionadores.read", {
          pessoaId: this.dataPerson.id,
        });
        const rsp = await Api.request(rq);

        if (rsp.ret_id < 1) {
          this.ret_id = rsp.ret_id;
          this.ret_msg = rsp.ret_msg;
          return;
        }

        this.personTransactioners =
          rsp.getTable("pessoas_transacionadores")?.getRowsObject() ?? [];
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
  },
});
