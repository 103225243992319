
import { cep } from "@/lib/validations";
import Vue, { PropType } from "vue";
import aTextField from "./a-text-field.vue";
import { queryCEP } from "@/lib/viacep";

export default Vue.extend({
  name: "ATextFieldCEP",

  components: { aTextField },

  props: {
    autofocus: {
      type: Boolean as PropType<boolean | undefined>,
      default: undefined,
    },

    backgroundColor: {
      type: String as PropType<string>,
      default: "inputbg",
    },

    disabled: {
      type: Boolean as PropType<boolean>,
      default: false,
    },

    value: {
      type: String as PropType<string>,
      default: "",
    },

    raw: {
      type: String as PropType<string>,
      default: "",
    },

    label: {
      type: String as PropType<string>,
      required: true,
    },

    rules: {
      type: Array as PropType<Array<(value: string) => string | boolean>>,
      default: () => [],
    },
  },

  computed: {
    cRules(): Array<(value: string) => string | boolean> {
      return [...this.rules, cep];
    },
  },

  watch: {
    value() {
      this.emit(this.value);
    },
  },

  methods: {
    filter(event: KeyboardEvent) {
      if (/^[0-9]+$/.test(event.key)) return true;
      else event.preventDefault();
    },

    emit(value: string) {
      const raw = value.replace(/[^\d]/g, "");
      this.$emit("update:raw", raw);

      let rule: RegExp;
      let format: string;
      if (raw.length >= 5 && raw.length <= 8) {
        rule = /^(\d{5})(\d*)$/;
        format = "$1-$2";
      } else {
        this.$emit("input", raw);
        return;
      }

      if (raw.length === 8) this.emitAddress(raw);

      value = raw.replace(rule, format);
      this.$emit("input", value);
    },

    emitAddress(cep: string) {
      queryCEP(cep).then((data) => {
        if (data.erro !== true) this.$emit("found", data);
      });
    },
  },
});
