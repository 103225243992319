
import ABtn from "@/components/elements/a-btn.vue";
import ATextField from "@/components/elements/a-text-field.vue";
import vAlertMessage from "@/components/layout/v-alert-message.vue";
import { Rq } from "@/lib/amdt";
import Api from "@/lib/api";
import IPeopleAddressType from "@/lib/interfaces/people-address-type";
import IPersonAddress from "@/lib/interfaces/person-address";
import Vue, { PropType } from "vue";
import { DataOptions } from "vuetify";
import PersonAddress from "./person-address.vue";
import ADataTable from "@/components/elements/a-data-table.vue";
import ACard from "@/components/elements/a-card.vue";
import ASelectStatus from "@/components/elements/a-select-status.vue";

export default Vue.extend({
  name: "PeorsonAddressesList",

  components: {
    vAlertMessage,
    ABtn,
    PersonAddress,
    ATextField,
    ADataTable,
    ACard,
    ASelectStatus,
  },

  props: {
    personId: {
      type: String as PropType<string>,
      required: true,
    },
  },

  data() {
    return {
      pendingConnections: 0,
      ret_id: 1,
      ret_msg: "",

      personAddressDialog: false,

      total: 0,
      search: "",
      desativado: "0",

      options: {} as DataOptions,

      personAddresses: new Array<IPersonAddress>(),
      peopleAddressTypes: new Array<IPeopleAddressType>(),
      personAddress: undefined as IPersonAddress | undefined,

      headers: [
        { text: "Tipo", value: "cTipo", sortable: false },
        { text: "CEP", value: "cep" },
        { text: "Endereço", value: "endereco" },
        { text: "Número", value: "numero" },
        { text: "Complemento", value: "complemento" },
        { text: "Bairro", value: "bairro" },
        { text: "Cidade", value: "cidade" },
        { text: "UF", value: "uf", sortable: false },
        { text: "Status", value: "cDesativado", sortable: false },
        { text: "Ações", value: "actions", sortable: false },
      ],
    };
  },

  watch: {
    personId() {
      this.getPersonAddresses().then();
    },

    options: {
      deep: true,
      handler() {
        this.getPersonAddresses().then();
      },
    },
  },

  computed: {
    cPersonAddresses(): Array<Record<string, unknown>> {
      return this.personAddresses.map((personAddress) => ({
        ...personAddress,
        cTipo:
          this.peopleAddressTypes.find(
            (item) => item.id === personAddress.tipo_id
          )?.descricao ?? personAddress.tipo_id,
        cDesativado: personAddress.desativado === "0" ? "Ativo" : "Inativo",
      }));
    },

    loading: {
      get(): boolean {
        return this.pendingConnections > 0;
      },
      set(val: boolean) {
        this.pendingConnections += val ? 1 : -1;
      },
    },
  },

  created() {
    this.getPeopleAddressTypes().then();
  },

  methods: {
    openPersonAddress(id: string) {
      const personAddress = this.personAddresses.find(
        (personAddress) => personAddress.id === id
      );

      this.personAddress = { ...personAddress } as IPersonAddress;

      this.personAddressDialog = true;
    },

    async getPersonAddresses() {
      try {
        if (!this.personId) return;

        this.loading = true;
        this.ret_id = 1;
        this.ret_msg = "";

        const rq = new Rq("pessoas_enderecos.read", {
          pessoaId: this.personId,
          search: this.search,
          desativado: this.desativado,
          itemsPerPage: this.options.itemsPerPage.toString(),
          page: this.options.page.toString(),
          sortBy: this.options.sortBy[0] ?? "id",
          sortOrder: this.options.sortDesc[0] ? "DESC" : "ASC",
        });
        const rsp = await Api.request(rq);

        if (rsp.ret_id < 1) {
          this.ret_id = rsp.ret_id;
          this.ret_msg = rsp.ret_msg;
          return;
        }

        this.total = Number(rsp.params["total"]);
        this.options.itemsPerPage = Number(rsp.params["itemsPerPage"]);
        this.options.page = Number(rsp.params["page"]);

        this.personAddresses =
          rsp.getTable("pessoas_enderecos")?.getRowsObject() ?? [];
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },

    async getPeopleAddressTypes() {
      try {
        this.loading = true;
        this.ret_id = 1;
        this.ret_msg = "";

        const rq = new Rq("pessoas_enderecos_tipos.read");
        const rsp = await Api.request(rq);

        if (rsp.ret_id < 1) {
          this.ret_id = rsp.ret_id;
          this.ret_msg = rsp.ret_msg;
          return;
        }

        this.peopleAddressTypes =
          rsp.getTable("pessoas_enderecos_tipos")?.getRowsObject() ?? [];
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
  },
});
